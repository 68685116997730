const pages = [
	{
		id: 'home',
		name: 'Home',
		path: '/',
	},
	{
		id: 'contact',
		name: 'Contact',
		path: '/contact',
	},
]

export default pages
